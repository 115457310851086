import GoogleMap from 'elements/map/GoogleMap';
import React, { useEffect, useRef, useState } from 'react';
import {
    fetchNeighborhoods,
    fetchPlaces,
    fetchProperties,
    setMapFullscreen,
    setMapMarker,
} from 'actions';
import { intersection } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'reflexbox';
import {
    BICYCLE,
    NEIGHBORHOOD,
    nodes,
    PROPERTY,
    TRAFFIC,
    TRANSIT,
} from './PlacesMenu';
import { BUTTONS_STATE } from 'constants';
import MarkerContent from 'elements/map/markersContent';
import { Modal } from 'react-bootstrap';
import { setInfoWindow } from '../../actions/map';
import MapContext from '../../elements/map/MapContext';
import { MapMenu } from './MapMenu';
import { FullScreenButton, MapLoading, Wrapper } from './Styled';
import Measure from 'react-measure';

const PlacesMap = ({
    filtered,
    marker,
    single,
    mapOnly,
    onChange,
    customMarkers,
    isPropertiesSelected,
    allowFullScreen,
    neighborhoodId,
    globalMap,
    withShowSurroundingCheckbox = true,
    blockInitialFetching, //do not fetch data if not present (mainly looking the provided markers)
    noDistance,
    recenter,
    ...rest
}) => {
    const {
        neighborhoods,
        properties,
        visibilityFilter: { filtered: filteredProps },
        places = [],
        map: {
            buttonsState,
            fullscreen,
            infoWindow,
            marker: searchMarker,
            ...mapProps
        },
        customMap: {
            object: { messages, messagesEnabled },
        },
    } = useSelector(state => state);

    const dispatch = useDispatch();

    const [initialSelect, setInitialSelected] = useState(rest.selected); //to prevent resetting the selected state
    const [selected, setSelected] = useState(
        rest.selected ||
            nodes.map(k =>
                isPropertiesSelected && k.value === PROPERTY ? k.value : null
            )
    );

    const [map, setMap] = useState();
    const [mapsApi, setMapsApi] = useState();

    const mapWrapperRef = useRef(null);
    const [mapWrapperDims, setMapWrapperDims] = useState(null); //tracks map wrapper to dynamically set the menu limits

    useEffect(() => {
        if (mapWrapperRef?.current) {
            const sizes = mapWrapperRef.current.getBoundingClientRect();
            console.log(sizes.height);
            if (
                !mapWrapperDims?.height ||
                mapWrapperDims?.height !== sizes.height
            ) {
                setMapWrapperDims(sizes);
            }
        }
    }, [mapWrapperRef]);

    const onMapsLoad = (map, mapsApi) => {
        setMap(map);
        setMapsApi(mapsApi);
    };

    useEffect(() => {
        if (rest.selected.join('') !== initialSelect.join('')) {
            setSelected(rest.selected);
            setInitialSelected(rest.selected);
        }
    }, [rest.selected]);

    const isPlaceAllowed = place => {
        if (!neighborhoodId) return true;

        return `${place.neighborhood?.id}` === `${neighborhoodId}`;
    };

    const toggleFullScreen = () => {
        dispatch(setMapFullscreen(!fullscreen));
    };

    useEffect(() => {
        if (!blockInitialFetching && !neighborhoods.length)
            dispatch(fetchNeighborhoods());
        if (!blockInitialFetching && !single && !properties.length)
            dispatch(fetchProperties());
        if (!blockInitialFetching && !places.length) dispatch(fetchPlaces());

        return () => {
            if (marker) dispatch(setMapMarker());
        };
    }, []);

    const getMarkers = () => {
        const markers = marker ? [marker] : [];

        //if custom markers are passed
        if (customMarkers && customMarkers.length)
            customMarkers.map(m => markers.push(m));

        if (selected?.includes(PROPERTY)) {
            if (!filtered) {
                properties
                    .filter(
                        p => p.status === 'available' || p.status === 'soon'
                    )
                    .map(p => {
                        return markers.push({
                            ...p.geoPoint,
                            object: p,
                            markerType: 'property',
                        });
                    });
            } else {
                filteredProps.map(p => {
                    return markers.push({
                        ...p.geoPoint,
                        object: p,
                        markerType: 'property',
                    });
                });
            }
        }
        if (selected.includes(NEIGHBORHOOD)) {
            neighborhoods
                .filter(n => (neighborhoodId ? n.id === neighborhoodId : true))
                .map(n => {
                    return markers.push({
                        ...n.location,
                        object: n,
                        markerType: 'neighborhood',
                    });
                });
        }

        places.map(place => {
            if (
                intersection(selected, place.types).length &&
                (neighborhoodId ? isPlaceAllowed(place) : true)
            ) {
                markers.push({
                    ...place.location,
                    object: place,
                    markerType: 'place',
                });
            }
        });
        return markers;
    };

    const getDrawings = () => {
        const listOfDrawings = [];

        if (searchMarker && searchMarker.drawing) {
            listOfDrawings.push(searchMarker);
        }

        if (rest.drawings?.length) {
            listOfDrawings.push(...rest.drawings);
        }
        if (selected.includes(NEIGHBORHOOD)) {
            listOfDrawings.push(...neighborhoods);
        }
        places.map(place => {
            if (intersection(selected, place.types).length && place.drawing) {
                listOfDrawings.push(place);
            }
        });
        return listOfDrawings;
    };

    const close = e => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(setInfoWindow());
    };

    return (
        <MapContext.Provider value={{ map, mapsApi, mapWrapperDims }}>
            <Measure
                bounds
                onResize={contentRect => {
                    setMapWrapperDims(contentRect.bounds);
                }}>
                {({ measureRef }) => (
                    <Wrapper
                        width="100%"
                        height="100%"
                        fullScreen={fullscreen}
                        ref={measureRef}>
                        {allowFullScreen ? (
                            <FullScreenButton
                                className="material-icons"
                                onClick={toggleFullScreen}>
                                {fullscreen ? 'close' : 'fullscreen'}
                            </FullScreenButton>
                        ) : undefined}
                        {!mapOnly ? (
                            <MapMenu
                                globalMap={globalMap}
                                nodes={nodes}
                                selected={selected}
                                setSelected={setSelected}
                                isPropertiesSelected={isPropertiesSelected}
                                noDistance={noDistance}
                            />
                        ) : undefined}
                        <Box width={1} style={{ position: 'relative' }}>
                            {marker ||
                            customMarkers?.length ||
                            properties.length ||
                            filteredProps.length ? (
                                <GoogleMap
                                    zoom={14}
                                    height="100%"
                                    markers={getMarkers()}
                                    transit={selected.includes(TRANSIT)}
                                    traffic={selected.includes(TRAFFIC)}
                                    bicycle={selected.includes(BICYCLE)}
                                    onMapsLoad={onMapsLoad}
                                    showDistance={
                                        buttonsState === BUTTONS_STATE.DISTANCE
                                    }
                                    messagesEnabled={messagesEnabled}
                                    showMessages={
                                        buttonsState === BUTTONS_STATE.MESSAGES
                                    }
                                    {...rest}
                                    mapShapes={getDrawings()}
                                    onChange={onChange}
                                />
                            ) : (
                                <MapLoading>Loading map...</MapLoading>
                            )}
                        </Box>
                    </Wrapper>
                )}
            </Measure>
            <Modal
                show={infoWindow !== null && infoWindow !== undefined}
                className="placeModal"
                size="lg"
                animation={false}
                centered>
                <Modal.Body>
                    <MarkerContent
                        {...infoWindow}
                        markerType={infoWindow?.propId ? 'property' : 'place'}
                        onClose={close}
                        isShowing={infoWindow !== null}
                    />
                </Modal.Body>
            </Modal>
        </MapContext.Provider>
    );
};

PlacesMap.defaultProps = {
    allowFullScreen: true,
    selected: [],
};
export default PlacesMap;
