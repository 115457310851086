import React from 'react';
import { Flex, Box } from 'reflexbox';
import furniture from '../../../components/common/Furniture';
import Availability, {
    STATUSES,
} from '../../../components/common/tile/Availability';

const Attrib = ({ caption, content, ...rest }) => (
    <Flex bg="#f7f7f7" px="10px" py="5px" {...rest}>
        <Box fontWeight="700" flexShrink={0} minWidth={76} mr={1}>
            {caption}:
        </Box>
        <Box dangerouslySetInnerHTML={{ __html: content }}></Box>
    </Flex>
);

const PropertyAttribComments = props => {
    const {
        availabilityStatus,
        availabilityComment,
        furnitureComment,
        priceComment,
        furnitureStatus,
        note,
        ...rest
    } = props;

    return (
        <>
            <Box mb={4} style={{ borderRadius: '8px', overflow: 'hidden' }}>
                {availabilityComment ? (
                    <Attrib
                        caption={'Availability'} //STATUSES[availabilityStatus]}
                        content={availabilityComment}
                        style={{ borderBottom: '3px solid #FFF' }}
                    />
                ) : undefined}
                {furnitureComment ? (
                    <Attrib
                        caption={'Furniture'} //furniture(furnitureStatus)}
                        content={furnitureComment}
                        style={{ borderBottom: '3px solid #FFF' }}
                    />
                ) : undefined}
                {priceComment ? (
                    <Attrib
                        caption="Price"
                        content={priceComment}
                        style={{ borderBottom: '3px solid #FFF' }}
                    />
                ) : undefined}
                {note ? <Attrib caption="Note" content={note} /> : undefined}
            </Box>
        </>
    );
};

export default PropertyAttribComments;
