import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Suspense, useEffect } from 'react';
import './styles/main.scss';
import { getArticleTags, getCurrencies, getCurrentUser } from 'actions';
import AppLayout from 'layouts/AppLayout';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
// eslint-disable-next-line import/no-cycle
import { BrowserView, isMobile } from 'react-device-detect';
import Routes from './Routes';
import { FloatingButton } from './components/FloatingButton/index';
import Map from './components/map';
import Loading from './elements/Loading';

const HEADLESS_PAGES = ['/whyjr', '/retal_east_jerusalem'];
const HEADLESS_MOBILE = ['/whyjr', '/retal_east_jerusalem', '/CustomListings'];

// eslint-disable-next-line react/function-component-definition
const App = props => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (window.innerWidth <= 960) {
            // eslint-disable-next-line global-require
            require('styles/mobile.scss');
        }
        dispatch(getCurrentUser());
        dispatch(getCurrencies());
        dispatch(getArticleTags());
    }, []);

    const { pathname } = useLocation();

    const isHeadless = (isMobile ? HEADLESS_MOBILE : HEADLESS_PAGES).find(
        page => pathname.includes(page)
    );

    return (
        <Suspense fallback={<div className="suspense">loading....</div>}>
            <AppLayout headless={isHeadless}>
                <Routes store={props.store} />
                <Loading />
                <Map />
                <BrowserView>
                    <FloatingButton />
                </BrowserView>
            </AppLayout>
        </Suspense>
    );
};

export default App;
