import Header from 'components/header';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Footer from './Footer';

const AppLayout = ({ children, headless }) => {
    const { pathname } = useLocation();
    const isHomepage = pathname === '/';

    return (
        <>
            {!headless ? <Header /> : undefined}
            <main className={`site-content ${isHomepage ? 'home' : ''}`}>
                {children}
            </main>
            <Footer />
        </>
    );
};

export default AppLayout;
