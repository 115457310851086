export const PATH = '/';
export const ROOT_URL = `${PATH}api`;

/******************************************************************
 * APP
 *****************************************************************/

export const LOADING = 'LOADING';
export const IDLE = 'IDLE';
export const FETCHING = 'FETCHING';
export const TOGGLE_MAP = 'TOGGLE_MAP';
export const SET_MAP_MARKER = 'SET_MAP_MARKER';
export const SET_MAP_ZOOM = 'SET_MAP_ZOOM';
export const SET_MAP_CENTER = 'SET_MAP_CENTER';
export const HIGHLIGHT_MARKER = 'HIGHLIGHT_MARKER';
export const SET_MAP_BUTTONS_STATE = 'SET_MAP_BUTTONS_STATE';
export const SET_MAP_FULLSCREEN = 'SET_MAP_FULLSCREEN';
export const SET_MAP_INFO_WINDOW = 'SET_MAP_INFO_WINDOW';
export const SHOW_SEPARATION_LINE = 'SHOW_SEPARATION_LINE';

export const BUTTONS_STATE = {
    NONE: 'none',
    SEARCH: 'search',
    LAYER: 'layer',
    DISTANCE: 'distance',
    MESSAGES: 'messages',
};

/******************************************************************
 * USER
 *****************************************************************/

export const CURRENT_USER = 'CURRENT_USER';
export const CURRENT_USER_SUCCESS = 'CURRENT_USER_SUCCESS';
export const CURRENT_USER_FAILURE = 'CURRENT_USER_FAILURE';

/******************************************************************
 * PROPERTIES
 *****************************************************************/
//Properties list
export const FETCH_PROPERTIES = 'FETCH_PROPERTIES';
export const FETCHING_PROPERTIES = 'FETCHING_PROPERTIES';
export const FETCH_PROPERTIES_SUCCESS = 'FETCH_PROPERTIES_SUCCESS';
export const FETCH_PROPERTIES_FAILURE = 'FETCH_PROPERTIES_FAILURE';

//Related Properties list
export const FETCH_RELATED_PROPERTIES = 'FETCH_RELATED_PROPERTIES';
export const FETCH_RELATED_PROPERTIES_SUCCESS =
    'FETCH_RELATED_PROPERTIES_SUCCESS';
export const FETCH_RELATED_PROPERTIES_FAILURE =
    'FETCH_RELATED_PROPERTIES_FAILURE';

export const GET_FEATURED_PROPERTIES = 'GET_FEATURED_PROPERTIES';
export const GET_FEATURED_PROPERTIES_SUCCESS =
    'GET_FEATURED_PROPERTIES_SUCCESS';
export const GET_FEATURED_PROPERTIES_FAILURE =
    'GET_FEATURED_PROPERTIES_FAILURE';

//Create/Save property
export const SAVE_PROPERTY = 'SAVE_PROPERTY';
export const SAVE_PROPERTY_SUCCESS = 'SAVE_PROPERTY_SUCCESS';
export const SAVE_PROPERTY_FAILURE = 'SAVE_PROPERTY_FAILURE';

//Update featured property
export const UPDATE_FEATURED_PROPERTY = 'UPDATE_FEATURED_PROPERTY';
export const UPDATE_FEATURED_PROPERTY_SUCCESS =
    'UPDATE_FEATURED_PROPERTY_SUCCESS';
export const UPDATE_FEATURED_PROPERTY_FAILURE =
    'UPDATE_FEATURED_PROPERTY_FAILURE';

//Fetch property
export const FETCH_PROPERTY = 'FETCH_PROPERTY';
export const FETCH_PROPERTY_SUCCESS = 'FETCH_PROPERTY_SUCCESS';
export const FETCH_PROPERTY_FAILURE = 'FETCH_PROPERTY_FAILURE';

//Delete property
export const DELETE_PROPERTY = 'DELETE_PROPERTY';
export const DELETE_PROPERTY_SUCCESS = 'DELETE_PROPERTY_SUCCESS';
export const DELETE_PROPERTY_FAILURE = 'DELETE_PROPERTY_FAILURE';

export const RESET_PROPERTY = 'RESET_PROPERTY';

/******************************************************************
 * FILTERS, SORT AND VIEW
 *****************************************************************/
export const SET_VISIBILITY_FILTER = 'SET_VISIBILITY_FILTER';
export const REMOVE_VISIBILITY_FILTER = 'REMOVE_VISIBILITY_FILTER';

export const APPEND_FILTER = 'APPEND_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const SHOW_ALL_FILTER = 'SHOW_ALL';
export const FETCHING_FILTER_OPTIONS = 'FETCHING_FILTER_OPTIONS';
export const FILTER_OPTIONS_FETCHED = 'FILTER_OPTIONS_FETCHED';
export const FILTER = 'FILTER';

export const PAGINATE = 'PAGINATE';
export const SWITCH_VIEW = 'SWITCH_VIEW';
export const SORT_PROPERTIES = 'SORT_PROPERTIES';
export const SORT_PROPERTIES_SUCCESS = 'SORT_PROPERTIES_SUCCESS';

/******************************************************************
 * BUILDINGS
 *****************************************************************/
//Buildings list
export const FETCH_BUILDINGS = 'FETCH_BUILDINGS';
export const FETCH_BUILDINGS_SUCCESS = 'FETCH_BUILDINGS_SUCCESS';
export const FETCH_BUILDINGS_FAILURE = 'FETCH_BUILDINGS_FAILURE';

//Create/Save building
export const SAVE_BUILDING = 'SAVE_BUILDING';
export const SAVE_BUILDING_SUCCESS = 'SAVE_BUILDING_SUCCESS';
export const SAVE_BUILDING_FAILURE = 'SAVE_BUILDING_FAILURE';
export const FETCH_PROPERTIES_IN_BUILDING = 'FETCH_PROPERTIES_IN_BUILDINGS';

//Fetch building
export const FETCH_BUILDING = 'FETCH_BUILDING';
export const FETCH_BUILDING_SUCCESS = 'FETCH_BUILDING_SUCCESS';
export const FETCH_BUILDING_FAILURE = 'FETCH_BUILDING_FAILURE';

//Delete building
export const DELETE_BUILDING = 'DELETE_BUILDING';
export const DELETE_BUILDING_SUCCESS = 'DELETE_BUILDING_SUCCESS';
export const DELETE_BUILDING_FAILURE = 'DELETE_BUILDING_FAILURE';

/******************************************************************
 * ITEMS
 *****************************************************************/
//Items list
export const FETCH_ITEMS = 'FETCH_ITEMS';
export const FETCH_ITEMS_SUCCESS = 'FETCH_ITEMS_SUCCESS';
export const FETCH_ITEMS_FAILURE = 'FETCH_ITEMS_FAILURE';

//Create/Save item
export const SAVE_ITEM = 'SAVE_ITEM';
export const SAVE_ITEM_SUCCESS = 'SAVE_ITEM_SUCCESS';
export const SAVE_ITEM_FAILURE = 'SAVE_ITEM_FAILURE';
export const FETCH_SIMILAR_ITEMS = 'FETCH_SIMILAR_ITEMS';

//Fetch item
export const FETCH_ITEM = 'FETCH_ITEM';
export const FETCH_ITEM_SUCCESS = 'FETCH_ITEM_SUCCESS';
export const FETCH_ITEM_FAILURE = 'FETCH_ITEM_FAILURE';

//Delete item
export const DELETE_ITEM = 'DELETE_ITEM';
export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_FAILURE = 'DELETE_ITEM_FAILURE';

/******************************************************************
 * NEIGHBORHOODS
 *****************************************************************/
export const FETCH_NEIGHBORHOODS = 'FETCH_NEIGHBORHOODS';
export const FETCH_NEIGHBORHOODS_SUCCESS = 'FETCH_NEIGHBORHOODS_SUCCESS';
export const FETCH_NEIGHBORHOODS_FAILURE = 'FETCH_NEIGHBORHOODS_FAILURE';

export const SAVE_NEIGHBORHOOD = 'SAVE_NEIGHBORHOOD';
export const SAVE_NEIGHBORHOOD_SUCCESS = 'SAVE_NEIGHBORHOOD_SUCCESS';
export const SAVE_NEIGHBORHOOD_FAILURE = 'SAVE_NEIGHBORHOOD_FAILURE';

export const GET_NEIGHBORHOOD = 'GET_NEIGHBORHOOD';
export const GET_NEIGHBORHOOD_SUCCESS = 'GET_NEIGHBORHOOD_SUCCESS';
export const GET_NEIGHBORHOOD_FAILURE = 'GET_NEIGHBORHOOD_FAILURE';

export const RESET_NEIGHBORHOOD = 'RESET_NEIGHBORHOOD';

/******************************************************************
 * Realtor Service Agreement
 *****************************************************************/
export const FETCH_RSAS = 'FETCH_RSAS';
export const FETCH_RSAS_SUCCESS = 'FETCH_RSAS_SUCCESS';
export const FETCH_RSAS_FAILURE = 'FETCH_RSAS_FAILURE';

export const SAVE_RSA = 'SAVE_RSA';
export const SAVE_RSA_SUCCESS = 'SAVE_RSA_SUCCESS';
export const SAVE_RSA_FAILURE = 'SAVE_RSA_FAILURE';

export const GET_RSA = 'GET_RSA';
export const GET_RSA_SUCCESS = 'GET_RSA_SUCCESS';
export const GET_RSA_FAILURE = 'GET_RSA_FAILURE';

export const DELETE_RSA = 'DELETE_RSA';
export const DELETE_RSA_SUCCESS = 'DELETE_RSA_SUCCESS';
export const DELETE_RSA_FAILURE = 'DELETE_RSA_FAILURE';

export const RESET_RSA = 'RESET_RSA';

/******************************************************************
 * PLACES
 *****************************************************************/
export const FETCH_PLACES = 'FETCH_PLACES';
export const FETCH_PLACES_SUCCESS = 'FETCH_PLACES_SUCCESS';
export const FETCH_PLACES_FAILURE = 'FETCH_PLACES_FAILURE';

export const SAVE_PLACE = 'SAVE_PLACE';
export const SAVE_PLACE_SUCCESS = 'SAVE_PLACE_SUCCESS';
export const SAVE_PLACE_FAILURE = 'SAVE_PLACE_FAILURE';

export const GET_PLACE = 'GET_PLACE';
export const GET_PLACE_SUCCESS = 'GET_PLACE_SUCCESS';
export const GET_PLACE_FAILURE = 'GET_PLACE_FAILURE';

export const DELETE_PLACE = 'DELETE_PLACE';
export const DELETE_PLACE_SUCCESS = 'DELETE_PLACE_SUCCESS';
export const DELETE_PLACE_FAILURE = 'DELETE_PLACE_FAILURE';

export const RESET_PLACE = 'RESET_PLACE';

/******************************************************************
 * CUSTOM MAP
 *****************************************************************/
export const FETCH_CUSTOM_MAPS = 'FETCH_CUSTOM_MAPS';
export const FETCH_CUSTOM_MAPS_SUCCESS = 'FETCH_CUSTOM_MAPS_SUCCESS';
export const FETCH_CUSTOM_MAPS_FAILURE = 'FETCH_CUSTOM_MAPS_FAILURE';

export const SAVE_CUSTOM_MAP = 'SAVE_CUSTOM_MAP';
export const SAVE_CUSTOM_MAP_SUCCESS = 'SAVE_CUSTOM_MAP_SUCCESS';
export const SAVE_CUSTOM_MAP_FAILURE = 'SAVE_CUSTOM_MAP_FAILURE';

export const GET_CUSTOM_MAP = 'GET_CUSTOM_MAP';
export const GET_CUSTOM_MAP_SUCCESS = 'GET_CUSTOM_MAP_SUCCESS';
export const GET_CUSTOM_MAP_FAILURE = 'GET_CUSTOM_MAP_FAILURE';

export const DELETE_CUSTOM_MAP = 'DELETE_CUSTOM_MAP';
export const DELETE_CUSTOM_MAP_SUCCESS = 'DELETE_CUSTOM_MAP_SUCCESS';
export const DELETE_CUSTOM_MAP_FAILURE = 'DELETE_CUSTOM_MAP_FAILURE';

export const RESET_CUSTOM_MAP = 'RESET_CUSTOM_MAP';

export const REMOVE_PROPERTY_FROM_ALL_MAPS = 'REMOVE_PROPERTY_FROM_ALL_MAPS';

export const UPDATE_RELEVANCE = 'UPDATE_RELEVANCE';
export const SET_CUSTOMER_COMMENT = 'SET_CUSTOMER_COMMENT';

export const CUSTOM_MAP_FILTER_REQUEST = 'CUSTOM_MAP_FILTER_REQUEST';
export const CUSTOM_MAP_FILTER_APPLY = 'CUSTOM_MAP_FILTER_APPLY';
export const FILTER_CUSTOM_MAP_PROPERTIES = 'FILTER_CUSTOM_MAP_PROPERTIES';
export const RESET_CUSTOM_MAP_FILTERS = 'RESET_CUSTOM_MAP_FILTERS';
export const RESET_CUSTOM_MAP_FILTERS_APPLIED =
    'RESET_CUSTOM_MAP_FILTERS_APPLIED';
export const FILTER_CUSTOM_MAP_PROPS = 'FILTER_CUSTOM_MAP_PROPS';
export const TOGGLE_MESSAGES_FULLSCREEN = 'TOGGLE_MESSAGES_FULLSCREEN';

/******************************************************************
 * TESTIMONIALS
 *****************************************************************/
export const FETCH_TESTIMONIALS = 'FETCH_TESTIMONIALS';
export const FETCH_TESTIMONIALS_SUCCESS = 'FETCH_TESTIMONIALS_SUCCESS';
export const FETCH_TESTIMONIALS_FAILURE = 'FETCH_TESTIMONIALS_FAILURE';

export const FETCH_TESTIMONIAL = 'FETCH_TESTIMONIAL';
export const FETCH_TESTIMONIAL_SUCCESS = 'FETCH_TESTIMONIAL_SUCCESS';
export const FETCH_TESTIMONIAL_FAILURE = 'FETCH_TESTIMONIAL_FAILURE';

export const SAVE_TESTIMONIAL = 'SAVE_TESTIMONIAL';
export const SAVE_TESTIMONIAL_SUCCESS = 'SAVE_TESTIMONIAL_SUCCESS';
export const SAVE_TESTIMONIAL_FAILURE = 'SAVE_TESTIMONIAL_FAILURE';

export const DELETE_TESTIMONIAL = 'DELETE_TESTIMONIAL';
export const DELETE_TESTIMONIAL_SUCCESS = 'DELETE_TESTIMONIAL_SUCCESS';
export const DELETE_TESTIMONIAL_FAILURE = 'DELETE_TESTIMONIAL_FAILURE';

export const RESET_TESTIMONIAL = 'RESET_TESTIMONIAL';

/**********************************************************************
 *
 *  CURRENCIES
 *
 *********************************************************************/
export const GET_CURRENCIES = 'GET_CURRENCIES';
export const GET_CURRENCIES_SUCCESS = 'GET_CURRENCIES_SUCCESS';
export const CHANGE_CURRENCY = 'CHANGE_CURRENCY';

/**********************************************************************
 *
 *  CUSTOMERS
 *
 *********************************************************************/
export const FETCH_CUSTOMER = 'FETCH_CUSTOMER';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_FAILURE = 'FETCH_CUSTOMER_FAILURE';

export const SAVE_CUSTOMER = 'SAVE_CUSTOMER';
export const SAVE_CUSTOMER_SUCCESS = 'SAVE_CUSTOMER_SUCCESS';
export const SAVE_CUSTOMER_FAILURE = 'SAVE_CUSTOMER_FAILURE';

export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_FAILURE = 'DELETE_CUSTOMER_FAILURE';

export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILURE = 'FETCH_CUSTOMERS_FAILURE';

export const RESET_CUSTOMER = 'RESET_CUSTOMER';

/******************************************************************
 * ARTICLES
 *****************************************************************/
export const FETCH_ARTICLES = 'FETCH_ARTICLES';
export const FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS';
export const FETCH_ARTICLES_FAILURE = 'FETCH_ARTICLES_FAILURE';

export const SAVE_ARTICLE = 'SAVE_ARTICLE';
export const SAVE_ARTICLE_SUCCESS = 'SAVE_ARTICLE_SUCCESS';
export const SAVE_ARTICLE_FAILURE = 'SAVE_ARTICLE_FAILURE';

export const GET_ARTICLE = 'GET_ARTICLE';
export const GET_ARTICLE_SUCCESS = 'GET_ARTICLE_SUCCESS';
export const GET_ARTICLE_FAILURE = 'GET_ARTICLE_FAILURE';

export const GET_ARTICLE_TAGS = 'GET_ARTICLE_TAGS';
export const GET_ARTICLE_TAGS_SUCCESS = 'GET_ARTICLE_TAGS_SUCCESS';
export const GET_ARTICLE_TAGS_FAILURE = 'GET_ARTICLE_TAGS_FAILURE';

export const DELETE_ARTICLE = 'DELETE_ARTICLE';
export const DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS';
export const DELETE_ARTICLE_FAILURE = 'DELETE_ARTICLE_FAILURE';

export const RESET_ARTICLE = 'RESET_ARTICLE';

/******************************************************************
 * QA
 *****************************************************************/
export const FETCH_QAS = 'FETCH_QAS';
export const FETCH_QAS_SUCCESS = 'FETCH_QAS_SUCCESS';
export const FETCH_QAS_FAILURE = 'FETCH_QAS_FAILURE';

export const SAVE_QA = 'SAVE_QA';
export const SAVE_QA_SUCCESS = 'SAVE_QA_SUCCESS';
export const SAVE_QA_FAILURE = 'SAVE_QA_FAILURE';

export const ADD_QA = 'ADD_QA';
export const EDIT_QA = 'EDIT_QA';
export const CANCEL_EDIT_QA = 'CANCEL_EDIT_QA';

export const SELECT_QA = 'SELECT_QA';
export const REORDER_QA = 'REORDER_QA';

export const DELETE_QA = 'DELETE_QA';
export const DELETE_QA_SUCCESS = 'DELETE_QA_SUCCESS';
export const DELETE_QA_FAILURE = 'DELETE_QA_FAILURE';

/**********************************************************************
 *
 *  ADMIN NEWSLETTER
 *
 *********************************************************************/
export const SEND_NEWSLETTER = 'SEND_NEWSLETTER';
export const SEND_NEWSLETTER_SUCCESS = 'SEND_NEWSLETTER_SUCCESS';
export const SEND_NEWSLETTER_FAILED = 'SEND_NEWSLETTER_FAILED';
export const SEND_NEWSLETTER_RESET = 'SEND_NEWSLETTER_RESET';

/**********************************************************************
 *
 *  BANNER IMAGE
 *
 *********************************************************************/
export const FETCH_BANNER_IMAGE = 'FETCH_BANNER_IMAGE';
export const FETCH_BANNER_IMAGE_SUCCESS = 'FETCH_BANNER_IMAGE_SUCCESS';
export const FETCH_BANNER_IMAGE_FAILED = 'FETCH_BANNER_IMAGE_FAILED';

export const SAVE_BANNER_IMAGE = 'SAVE_BANNER_IMAGE';
export const SAVE_BANNER_IMAGE_SUCCESS = 'SAVE_BANNER_IMAGE_BANNER';
export const SAVE_BANNER_IMAGE_FAILED = 'SAVE_BANNER_IMAGE_FAILED';

/**********************************************************************
 *
 *  DISTANCE
 *
 *********************************************************************/
export const SELECT_DISTANCE_POINT = 'SELECT_DISTANCE_POINT';
export const SWAP_DISTANCE_LOCATIONS = 'SWAP_DISTANCE_LOCATIONS';
export const RESET_DISTANCE_CALCULATE = 'RESET_DISTANCE_CALCULATE';
export const TOGGLE_PINS = 'TOGGLE_PINS';
export const SET_ROUTE_TYPE = 'SET_ROUTE_TYPE';

export const MARK_MESSAGES_AS_READ = 'MARK_MESSAGES_AS_READ';
export const MESSAGES_REPLY = 'MESSAGES_REPLY';

export const SELECTED_SURROUNDINGS = [
    'property',
    'landmark',
    'school',
    'park',
    'restaurant',
    'sport',
    'commercial',
    'other',
    'supermarket',
    'tram',
];

/********************************************************
 *
 * Notification emails constants
 *
 ********************************************************/
export const CUSTOM_LISTING_EMAIL = 'customLising';
export const CUSTOM_LISTING_UPDATE_EMAIL = 'customLisingUpdate';
export const CUSTOM_LISTING_GENERAL_MESSAGE_EMAIL =
    'customLisingGeneralMessageWithoutProps';
export const CUSTOM_LISTING_GENERAL_MESSAGE_WITH_PROPS_EMAIL =
    'customLisingGeneralMessageWithProps';
export const CUSTOM_LISTING_PROPERTY_MESSAGE_EMAIL =
    'customLisingPropertyMessage';
