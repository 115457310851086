import React from 'react';
import { Flex, Box } from 'reflexbox';
import { trimEnd } from 'lodash';
import RoundedButton from 'components/common/RoundedButton';
import { PropertyTitle, TooltipClose } from '../StyledComponents';
import { selectDistancePoint, setMapButtonsState } from 'actions';
import { useDispatch } from 'react-redux';
import { BUTTONS_STATE } from 'constants';
import DistanceCalculator from './DistanceCalculator';
import Styled from 'styled-components';

const StyledLink = Styled(Box)`
    margin-left: 12px;
    
    font-size: 12px;
    font-weight:400;
    color: #0D6BEA;
    text-decoration: underline;
    &:hover{ 
        color: #0D6BEA
    }
`;

const LocationContent = props => {
    const {
        id,
        markerType,
        title,
        masterPhoto,
        photo,
        intro,
        description,
        propId,
        site,
        tour,
        geoPoint,
        location,
        highlight,
        onClose,
        isShowing,
        noDistance,
        note,
        ...rest
    } = props;
    const image = masterPhoto || photo;

    const isProperty = markerType?.toLowerCase()?.indexOf('property') >= 0;
    const isNeighborhood =
        markerType?.toLowerCase()?.indexOf('neighborhood') >= 0;
    const other = !isNeighborhood && !isProperty;

    const dispatch = useDispatch();

    const getDistanceObject = () => ({
        id,
        point: {
            lat: location?.latitude || geoPoint.latitude,
            lng: location?.longitude || geoPoint.longitude,
        },
        name: title,
        markerType,
        title,
        masterPhoto,
        photo,
        intro,
        description,
        propId,
        site,
        tour,
        ...rest,
    });
    const handleDistanceFrom = e => {
        e.stopPropagation();
        dispatch(selectDistancePoint('origin', getDistanceObject()));
        dispatch(setMapButtonsState(BUTTONS_STATE.DISTANCE));
        onClose(e);
    };

    const handleDistanceTo = e => {
        e.stopPropagation();
        dispatch(selectDistancePoint('destination', getDistanceObject()));
        dispatch(setMapButtonsState(BUTTONS_STATE.DISTANCE));
        onClose(e);
    };

    return (
        <Box p={20}>
            <Flex justifyContent="space-between">
                <Box mb={15} width={'calc(100% - 30px)'}>
                    <PropertyTitle
                        as="div"
                        style={{
                            color: '#707070',
                            textDecoration: 'none',
                            margin: 0,
                        }}>
                        {title}
                        {site ? (
                            <StyledLink as="a" href={site} target="_blank">
                                more info
                            </StyledLink>
                        ) : undefined}
                    </PropertyTitle>
                </Box>

                <TooltipClose onClick={onClose} style={{ top: 10, right: 20 }}>
                    &times;
                </TooltipClose>
            </Flex>
            <Box style={{ position: 'relative' }} mb={15}>
                {image ? (
                    <Box
                        style={{
                            backgroundImage: `url(${trimEnd(image)}=s1000)`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            borderRadius: '7px',
                            width: '100%',
                            height: '174px',
                        }}
                    />
                ) : undefined}
                {isShowing && !noDistance ? (
                    <DistanceCalculator
                        handleDistanceFrom={handleDistanceFrom}
                        handleDistanceTo={handleDistanceTo}
                        image={image}
                    />
                ) : undefined}
            </Box>
            {!isProperty && (intro || description) ? (
                <Box
                    style={{ maxHeight: 315, overflow: 'auto' }}
                    dangerouslySetInnerHTML={{
                        __html: intro || description,
                    }}
                />
            ) : undefined}
            {tour ? (
                <Flex justifyContent="flex-end">
                    <RoundedButton
                        href={tour}
                        as="a"
                        target="_blank"
                        style={{
                            padding: '7px 20px',
                            margin: '6px 0 12px',
                        }}>
                        <Flex alignItems="center">
                            <Box>Tour the site</Box>
                            <Box
                                className="material-icons"
                                fontSize="15px"
                                ml={1}>
                                arrow_right_alt
                            </Box>
                        </Flex>
                    </RoundedButton>
                </Flex>
            ) : undefined}
            {isNeighborhood ? (
                <RoundedButton
                    href={`/neighborhoods/${id}`}
                    as="a"
                    target="_blank"
                    style={{
                        padding: '7px 20px',
                        margin: '12px 0',
                    }}>
                    <Flex alignItems="center">
                        <Box>View neighborhood</Box>
                        <Box className="material-icons" fontSize="15px" ml={10}>
                            arrow_right_alt
                        </Box>
                    </Flex>
                </RoundedButton>
            ) : undefined}
        </Box>
    );
};

export default LocationContent;
