import React, { useState } from 'react';
import { Box } from 'reflexbox';
import styled from 'styled-components';

export const ReplyWrapper = styled.div`
    border-top: 2px solid #e6e6e6;
    display: flex;
    align-items: center;
    padding-left: 8px;
    padding-bottom: 8px;
    padding-top: 4px;
    .material-icons {
        background: #fcf7ec;
        color: #cb9a22;
        border-radius: 50px;
        padding: 5px;
        transform: rotate(-30deg);
        cursor: pointer;
    }
`;

const Message = styled.div`
    max-height: ${({ limit }) => limit}px;
    width: calc(100% - 36px);
    overflow-y: auto;
    &,
    &:focus {
        outline: none;
    }
`;
const Placeholder = styled.div`
    user-select: none;
    position: absolute;
    opacity: 0.5;
    pointer-events: none;
`;

export const DynamicHeightInput = ({ onSend, placeholder, ...props }) => {
    const [showPlaceholder, setShowPlaceholder] = useState(!props.value);

    const inputRef = React.useRef(null);

    const getContent = () => {
        if (inputRef.current) return inputRef.current.innerHTML;
    };

    const onChange = e => {
        setShowPlaceholder(!getContent().length);
    };
    const sendMessage = () => {
        if (getContent().length) {
            onSend(getContent());
            inputRef.current.innerHTML = '';
            setShowPlaceholder(true);
        }
    };

    const handleBlur = () => {
        if (!getContent().length) setShowPlaceholder(true);
    };

    return (
        <ReplyWrapper>
            <Message
                contentEditable
                ref={inputRef}
                onKeyUp={onChange}
                onBlur={handleBlur}
                {...props}
            />
            {showPlaceholder ? (
                <Placeholder>{placeholder}</Placeholder>
            ) : undefined}
            <Box className="material-icons" onClick={sendMessage}>
                send
            </Box>
        </ReplyWrapper>
    );
};

DynamicHeightInput.defaultProps = {
    limit: 120,
};
